
import { computed, defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";
import { useRouter } from "vue-router";
import store from "@/store";
import moment from "moment/moment";

export default defineComponent({
  name: "CustomerDetails",
  computed: {
    moment() {
      return moment;
    }
  },

  setup() {
    onMounted(() => {
      setCurrentPageTitle("Зээлдэгчийн дэлгэрэнгүй");
    });

    const role = computed(() => {
      return store.getters.getRoleNames;
    });

    return {
      role
    };
  },
  mounted() {
    // if (
    //   this.role.includes("ROLE_MORE_TCUSTOMER") ||
    //   this.role.includes("ROLE_MORE_TLOAN") ||
    //   this.role.includes("ROLE_ADMIN")
    // )
    if (
      this.role.includes("ROLE_NEW_USER") ||
      this.role.includes("ROLE_ADMIN")
    )
      this.getCustomerDetails();
  },
  data() {
    const router = useRouter();
    const identityno = this.b64DecodeUnicode(this.$route.params.id);

    const getLoanDetails = loancode => {
      const id = this.b64EncodeUnicode(loancode);
      router.push({
        name: "refloandetails",
        params: { id: id }
      });
    };

    return {
      identityno,
      getLoanDetails,
      dataLoading: true,
      subDataLoading: true,
      customerDetailsNotFound: false,
      emptyText: "Өгөгдөл байхгүй байна.",
      isCitizen: false,
      customer: {
        id: "",
        createdDate: "",
        numofShareholderCustomers: "",
        createdUser: "",
        version: "",
        customerName: "",
        lastUpdateDate: "",
        lastUpdateUser: "",
        lastUpdatePatch: "",
        isForeign: "",
        legalStatus: "",
        numofShareholderOrgs: "",
        createdPatch: "",
        numofRelatedOrgs: "",
        identityNo: "",
        stateRegisterNo: "",
        status: "",
        lastName: "",
        isCitizen: "",
        numofRelatedCustomers: ""
      },
      customerDetails: {
        id: "",
        createdDate: "",
        phone: "",
        jobPhone: "",
        ceoRegnum: "",
        createdUser: "",
        ceoAddress: "",
        addressSoumDistrictCode: "",
        addressSoumDistrictName: "",
        addressZipcode: "",
        addressFull: "",
        ceoEmail: "",
        ceoIsforeign: "",
        familyNumofUnemployed: "",
        orgNumofEmployee: "",
        isEmployed: "",
        jobMail: "",
        version: "",
        addressApartmentName: "",
        addressBagKhorooCode: "",
        lastUpdateDate: "",
        lastUpdateUser: "",
        lastUpdatePatch: "",
        addressAimagCityName: "",
        ceoFamilyname: "",
        ceoPhone: "",
        createdPatch: "",
        addressAimagCityCode: "",
        ceoCivilId: "",
        jobName: "",
        identityNo: "",
        occupation: "",
        jobAddress: "",
        orgRating: "",
        ceoFirstname: "",
        status: "",
        birthDate: "",
        orgAgency: "",
        addressBagKhorooName: "",
        addressStreetName: "",
        addressRegionName: "",
        taxNumber: "",
        addressTownName: "",
        familyNumofMembers: "",
        orgCompanyType: "",
        email: "",
        ceoLastname: "",
        familyName: "",
        jobPosition: ""
      },
      selectedLoanCode: null,
      requiredData: [],
      customerRelations: [],
      collateralInformation: [],
      collateralOwners: [],
      loanInformation: [],
      loanSchedule: [],
      loanPayment: [],
      loanlineInformation: []
    };
  },
  methods: {
    async getCustomerDetails() {

      this.dataLoading = true;

      try {
        const res = await ApiService.get("refcustomerdetails", `?identityno=${this.identityno}`);
        const result = res.data;

        if (result.code === 0) {
          ElNotification({
            title: "Алдаа",
            message: result.error,
            type: "error"
          });
        } else if (result.code === 2 || result.code === 401) {
          ElNotification({
            title: "Анхааруулга",
            message: result.warning,
            type: "warning"
          });
        } else {
          this.isCitizen = result.response.iscitizen;
          this.customer = result.response.customer;
          this.customerDetails = result.response.customerDetails;
          this.requiredData = result.response.annexInformation;
          this.customerRelations = result.response.customerRelations;
          this.loanInformation = result.response.loanInformation;
          this.loanlineInformation = result.response.loanlineInformation;
          this.collateralInformation = result.response.collateralInformation;
        }
      } catch (error) {
        ElNotification({
          title: "Алдаа",
          message: "Өгөгдөл уншихад алдаа гарлаа.",
          type: "error"
        });
      } finally {
        this.dataLoading = false;
      }
    },
    async getLoanTransactions(loancode) {
      this.selectedLoanCode = loancode;
      this.subDataLoading = true;
      try {
        const res = await ApiService.get("refloandetails", `?loancode=${this.selectedLoanCode}`);
        const result = res.data;

        if (result.code === 0) {
          ElNotification({
            title: "Алдаа",
            message: result.error,
            type: "error"
          });
        } else if (result.code === 2 || result.code === 401) {
          ElNotification({
            title: "Анхааруулга",
            message: result.warning,
            type: "warning"
          });
        } else {
          this.loanPayment = result.response.loanPayment;
          this.loanSchedule = result.response.loanSchedule;
        }
      } catch (error) {
        ElNotification({
          title: "Алдаа",
          message: "Өгөгдөл уншихад алдаа гарлаа.",
          type: "error"
        });
      } finally {
        this.subDataLoading = false;
      }
    },
    async getLoanCollaterals(collcode) {
      this.subDataLoading = true;
      try {
        const res = await ApiService.get("refcolldetails", `?collcode=${collcode}`);
        const result = res.data;

        if (result.code === 0) {
          ElNotification({
            title: "Алдаа",
            message: result.error,
            type: "error"
          });
        } else if (result.code === 2 || result.code === 401) {
          ElNotification({
            title: "Анхааруулга",
            message: result.warning,
            type: "warning"
          });
        } else {
          this.collateralOwners = result.response.collOwners;
        }
      } catch (error) {
        ElNotification({
          title: "Алдаа",
          message: "Өгөгдөл уншихад алдаа гарлаа.",
          type: "error"
        });
      } finally {
        this.subDataLoading = false;
      }
    }
  }
});
